<template>
  <div :class="[{'d-flex row': getIsAdmin}]">
    <a v-for="category in getCategories" class="inscricao" @click.prevent="toggleCategory($event, category)">
      <span class="categoria">
        <strong>{{ category.categoryName }}</strong> -
        <small>{{ category.subCategoryName }}</small> -
      </span>
      <span class="valor">
        {{ category.value | value }}
      </span>
      <span class="infos" v-if="category.subCategoryClassName && category.subCategoryClassDescription">
        {{ category.subCategoryClassName + ': ' + category.subCategoryClassDescription }}
      </span>
    </a>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ListCategories",
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    value(value) {
      return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    },
  },
  methods: {
    toggleCategory(event, category) {
      this.$emit('toggle-category', category)
      let target = event.target;
      if (!target.classList.contains('inscricao')) {
        target = target.closest('.inscricao');
      }
      target.classList.toggle('ativo')
    },
  },
  computed: {
    getIsAdmin() {
      return this.isAdmin
    },
    getCategories() {
      return _.orderBy(this.categories, ['categoryName', 'subCategoryName', 'subCategoryClassName'], ['asc', 'asc', 'asc']);
    },
  },
}
</script>

<style scoped>

</style>