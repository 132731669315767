<template>
  <div>
    <div key="modal" class="modal fade in show" v-if="showModal" ref="modal">
      <div v-if="isLoading" class="modal-dialog modal- modal-dialog-centered text-center" role="document">
        <span class="fa fa-spinner fa-spin fa-3x w-100"></span>
      </div>

      <div v-if="!isLoading" class="modal-dialog modal-dialog-scrollable" :class="`modal-${size}`" role="document">
        <div class="modal-content">
          <div class="modal-header vue-app" v-if="hasHeaderSlot">
            <div class="modal-title">
              <slot name="header"></slot>
            </div>
            <button class="close" type="button" @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <slot></slot>
          </div>

          <div class="modal-footer" v-if="hasFooterSlot">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showModal" key="backdrop" class="modal-backdrop fade in" ref="backdrop" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {}
  },
  computed: {
    hasHeaderSlot() {
      return this.$slots['header']
    },
    hasFooterSlot() {
      return this.$slots['footer']
    },
  },
  methods: {
    startOpenTransitionModal() {
      this.$refs.backdrop.classList.add("d-block");
      this.$refs.modal.classList.add("d-block");
    },
    endOpenTransitionModal() {
      this.$refs.backdrop.classList.add("show");
      this.$refs.modal.classList.add("show");
    },
    startCloseTransitionModal() {
      this.$refs.backdrop.classList.remove("show");
      this.$refs.modal.classList.remove("show");
    },
    endCloseTransitionModal() {
      this.$refs.backdrop.classList.remove("d-block");
      this.$refs.modal.classList.remove("d-block");
    },
  },
  watch: {
    showModal(current, old) {
      if (current === true) {
        document.getElementById('body').classList.add('modal-open')
      } else {
        document.getElementById('body').classList.remove('modal-open')
      }
    }
  }
}
</script>
