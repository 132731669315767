<template>
  <div id="warnings">
    <modal :show-modal="showModal" @close="closeModal" size="lg">
      <template v-slot:header>
        <h3>
          <strong>{{ warning.name }}</strong>
        </h3>
      </template>
      <span v-html="warning.content"></span>
    </modal>
  </div>
</template>

<script>
import Axios from "axios";

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
}

export default {
  name: 'Warnings',
  data() {
    return {
      showModal: false,
      warning: {
        name: '',
        content: '',
      },
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    }
  },
  async mounted() {
    let data = await Axios.get(window.location.origin + '/api/warnings')
        .then((response) => response.data);
    if (data.length > 0) {
      this.$data.warning = data[0]
      let now = new Date(),
          shouldShow = window.localStorage.getItem('shouldShow');

      if (shouldShow === null || new Date(shouldShow) <= now) {
        this.$data.showModal = true;
        now.addHours(1)
        window.localStorage.setItem('shouldShow', now.toString())
      }
    }
  }
}
</script>
