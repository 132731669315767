<template>
  <div id="cart">
    <div class="resumo-inscricao">
      <div v-if="cartItems.length === 0" class="text-center w-100">
        Nenhum item adicionado
      </div>
      <div v-else class="table-responsive">
        <table class="table">
          <caption class="hidden"></caption>
          <thead>
            <tr>
              <th scope="row" class="text-center">
                Item
              </th>
              <th scope="row" class="text-center">
                Quantidade
              </th>
              <th scope="row" class="text-center">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in cartItems">
              <td>
                <span class="categoria" v-if="item.type === types.subCategory || item.type === types.subCategoryClass">
                  <strong>{{ item.categoryName }}</strong>
                  <br>
                  <small>{{ item.subCategoryName }}</small>
                  <br>
                  <small data-toggle="tooltips" title="Desconto aplicado" v-if="item.discount">Com desconto</small>
                </span>
                <span v-else class="categoria">
                  <strong>{{ item.name }}</strong>
                  <br>
                  <small data-toggle="tooltips" title="Desconto aplicado" v-if="item.discount">Com desconto</small>
                </span>
              </td>
              <td class="text-center align-vertical-middle">
                {{ item.quantity }}
              </td>
              <td class="text-bold text-right align-vertical-middle">
                {{ value(item.calculated_value) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="total-inscricao clearfix">
      <small class="pull-left">Total</small> <span class="pull-right">{{ value(total) }}</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Cart",
  props: {
    propCart: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.cart = this.propCart
  },
  data() {
    return {
      total: 0,
      cart: []
    };
  },
  methods: {
    value(value) {
      return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    },
    getCartItems() {
      let total = 0,
          output = [];

      _.each(this.cart.getList(this.cart.types.service), function (item) {
        item.calculated_value = 0;

        item.calculated_value += item.discount ? item.double : item.value;

        total += item.quantity * item.calculated_value;

        output.push(item);
      });

      let list = _.concat(
          this.cart.getList(this.cart.types.subCategory),
          this.cart.getList(this.cart.types.subCategoryClass)
      );

      _.each(list, function (item, i) {
        item.calculated_value = 0;

        item.discount = i > 0 && item.double < item.value;

        item.calculated_value += i > 0 && item.double < item.value ? item.double : item.value;

        total += item.calculated_value;

        output.push(item);
      });

      this.total = total;

      return output;
    }
  },
  computed: {
    types() {
      return this.cart.types;
    },
    cartItems() {
      return this.getCartItems();
    },
  },
}
</script>

<style scoped>
.align-vertical-middle {
  vertical-align: middle !important;
}

small {
  font-size: 70%;
}
</style>