window._ = require('lodash');
require('axios');
try {
    window.$ = window.jQuery = require('jquery');
    require('jquery-mask-plugin');
} catch (e) {
}

import Vue from 'vue';
import swal from 'sweetalert2';
import axios from "axios";
import FormSubscription from './components/Subscription/FormSubscription';
import EmailLive from './components/EmailLive/EmailLive';
import copyTextToClipboard from './CopyToClipboard'
import Warnings from "./components/Warnings.vue";
import Modal from "./components/Admin/Modal.vue";

window.axios = axios;
window.swal = swal;
window.Vue = Vue;

window.copyTextToClipboard = copyTextToClipboard;

Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.component('modal', Modal);

jQuery(function ($) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $('#contact-form').submit(function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $form = $(this),
            $error = $form.find('.error-container'),
            action = $form.attr('action');

        $error.slideUp(750, function () {
            $error.hide();

            var $name = $form.find('.form-control-name'),
                $email = $form.find('.form-control-email'),
                $phone = $form.find('.form-control-phone'),
                $message = $form.find('.form-control-message');

            $.post(action,
                {
                    name: $name.val(),
                    email: $email.val(),
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    phone: $phone.val(),
                    message: $message.val()
                },
                function (data) {
                    swal.fire(
                        'Obrigado pelo contato',
                        'Em breve retornaremos seu contato',
                        'success',
                    );
                    $name.val('');
                    $email.val('');
                    $phone.val('');
                    $message.val('');
                }
            );
        });

        return false;
    });

    $('[data-format=hour]').mask('00:00');
    $('[data-format=date-hour]').mask('00/00/0000 00:00');
    $('[data-format=date]').mask('00/00/0000', {placeholder: "__/__/____"});
    $('[data-format=cep]').mask('00000-000');
    $('[data-format=cpf]').mask('000.000.000-00');
    $('[data-format=monetary]').mask('#.##0,00', {reverse: true});
    $('[data-format=number]').mask('#', {reverse: true});
    $('[data-format=phone]').mask('(00) 0000-00000');
    $('[data-format=cc]').mask('0000-0000-0000-0000');
    $('[data-format=mes]').mask('00', {placeholder: "__"});
    $('[data-format=ano]').mask('0000', {placeholder: "____"});
    $('[data-format=cvv]').mask('000Z', {
        placeholder: "___",
        translation: {
            'Z': {
                pattern: /[0-9]/, optional: true
            }
        }
    });
});
if (document.getElementById('appSubscription')) {
    let v = new Vue({
        el: '#appSubscription',
        data: {
            slug: null,
        },
        template: '<FormSubscription/>',
        components: {FormSubscription},
        render: h => h(FormSubscription),
        beforeMount() {
            this.slug = document.getElementById('appSubscription').dataset.eventSlug;
        }
    });
}
if (document.getElementById('appWarnings')) {
    new Vue({
        el: '#appWarnings',
        template: '<Warnings/>',
        components: {Warnings},
        render: h => h(Warnings),
    });
}
if (document.getElementById('appEmailLive')) {
    let v = new Vue({
        el: '#appEmailLive',
        components: {EmailLive},
    });
}