export const vueLaravelErrors = (type) => {
    return {
        data() {
            return {
                errors: {},
            }
        },
        methods: {
            fillErrors(errors) {
                if (type === 'array') {
                    errors = Object.entries(errors);
                    errors = errors.flatMap(error => {
                        return [...error[1]];
                    })
                } else if (type === 'object') {
                    errors = Object.entries(errors);
                    errors = errors.map(error => {
                        let key = error[0];
                        let value = error[1][0];
                        return {
                            [key]: value
                        };
                    })
                        .reduce((acc, current) => {
                            acc = Object.assign(acc, current);
                            return acc;
                        });
                }
                this.errors = errors;
            },
            resetErrors(){
                this.errors = {};
            },
            hasError(error){
                if(type === 'object'){
                    return error in this.errors ?? false;
                }
                return false;
            },
            getError(error){
                if(type === 'object'){
                    return this.errors[error] ?? '';
                }
                return '';
            },
            formatDate(data){
                return (new Date(data)).toLocaleDateString("pt-BR")
            },
            formatCPF(cpf){
                return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            },
            replaceLast(str, search, newStr){
                var a = str.split("");
                a[str.lastIndexOf(search)] = newStr;
                return a.join("");
            },
        },
    }
}
export default vueLaravelErrors('object');