<template>
  <div>
    <h3 class="column-title">
      Selecione os serviços
    </h3>
    <hr style="margin: 20px 0;">
    <div class="row">
      <div class="col-md-12">
        <Service v-for="service in getServices"
                 v-bind:key="service.id"
                 :service="service"
                 @toggle-service="toggleService"
                 @update-quantity="updateQuantity"/>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "./Service";

export default {
  name: "ListServices",
  components: {
    Service
  },
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toggleService(service) {
      this.$emit('toggle-service', service)
    },
    updateQuantity(service) {
      this.$emit('update-quantity', service)
    }
  },
  computed: {
    getServices() {
      return this.services;
    }
  },
}
</script>

<style scoped>

</style>