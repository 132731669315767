<template>
  <div :class="[{ativo: state}, 'servico']">
    <div class="servicos" @click.prevent="check()"></div>
    <div class="categoria">
      <div style="flex-grow: 1;" @click.prevent="check()">
        <strong>{{ service.name }}</strong> - <span class="valor">{{ service.value | value }}</span>
      </div>
      <div v-if="service.maximum_quantity" class="quantidade" @change.prevent.stop="changeAmount">
        <select class="form-select" v-model="quantity">
          <option v-for="i in service.maximum_quantity" :value="i">
            {{ padStart(i, 2, '0') }} {{ plural(i, 'Sessão', 'Sessões') }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Service",
  props: {
    service: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      state: false,
      quantity: 1
    }
  },
  filters: {
    value(value) {
      return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    },
  },
  methods: {
    check() {
      this.state = !this.state;
      this.$emit('toggle-service', this.service)
    },
    changeAmount() {
      this.service.quantity = this.quantity
      if (this.state) {
        this.$emit('update-quantity', this.service)
      }
    },
    padStart: (variable, length, string) => variable.toString().padStart(length, string),
    plural: (quantity, singular, plural) => quantity === 1 ? singular : plural
  },
}
</script>

<style scoped>
.servico {
  min-height: 45px;
  width: 100%;
  padding: 10px;
  margin: 0 0 10px 0;
  display: flex;
  border-radius: 5px;
  border-color: #eee;
  background-color: #f9f9f9;
  align-items: center;
}

.servico.ativo > .servicos {
  background: url(../../../site/images/check-ativo.png) no-repeat transparent;
}

.servico:hover {
  background-color: #eee;
}

.servico:hover > .servicos {
  background: url(../../../site/images/check-hover.png) no-repeat transparent;
}

.servico.ativo:hover > .servicos {
  background: url(../../../site/images/check-ativo.png) no-repeat transparent;
}

.servico > .servicos {
  height: 32px;
  width: 40px;
  padding: 0;
  margin: 0;
  display: inline-block;
  border-radius: unset;
  border-color: unset;
  background: unset;
  background: url(../../../site/images/check.png) no-repeat transparent;
}

.servico > .categoria {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-left: 10px;
}
</style>