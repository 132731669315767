import { render, staticRenderFns } from "./EmailLive.vue?vue&type=template&id=5a51d377&scoped=true&"
import script from "./EmailLive.vue?vue&type=script&lang=js&"
export * from "./EmailLive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a51d377",
  null
  
)

export default component.exports