<template>
  <div class="row">
    <template v-if="!enviado">
      <div class="col-md-8 col-md-offset-2">
        <div class="form-group" v-bind:class="{ 'has-error': hasError('name') }">
          <label for="name">Nome completo*</label>
          <input v-model="data.name" class="form-control" id="name" type="text" required>
          <span class="help-block" v-if="hasError('name')">
          {{ getError('name') }}
        </span>
        </div>
      </div>

      <div class="col-md-8 col-md-offset-2">
        <div class="form-group" v-bind:class="{ 'has-error': hasError('email') }">
          <label for="name">E-mail*</label>
          <input v-model="data.email" class="form-control" id="email" type="text" required>
          <span class="help-block" v-if="hasError('email')">
          {{ getError('email') }}
        </span>
        </div>
      </div>

      <div class="col-md-4 col-md-offset-8">
        <button class="btn btn-primary solid blank" @click="salvar">
          Se inscrever
        </button>
      </div>
    </template>
    <template v-if="enviado">
      <div class="col-md-5 col-md-offset-4 text-center">
        <img src="/site/images/pgto-sucesso.png" width="80" alt="">
        <br>
        <br>
        <p>Obrigado pela sua inscrição, em breve você receberá o link da live no seu e-mail.</p>
      </div>
    </template>
  </div>
</template>

<script>
import VueErrorMixin from '../../VueErrorMixin';
import axios from 'axios'
import Swal from "sweetalert2";
export default {
  name: "EmailLive",
  props:['slug'],
  mixins: [VueErrorMixin],
  data(){
    return {
      data:{
        name: '',
        email: '',
      },
      enviado: false,
    }
  },
  methods:{
    salvar(){
      this.resetErrors();
      Swal.showLoading();
      axios.post(`/api/events/event/${this.slug}/email-live`, this.data).then(
          ({data}) => {
            this.enviado = true;
            Swal.close();
          },
          ({response}) => {
            if (response && response.status === 422) {
              this.fillErrors(response.data.errors);
              Swal.close();
            }
          }
      )
    },
  },
}
</script>

<style scoped>

</style>